import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Message from '../components/Message.js';

function Messages() {
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const fetchMessages = async () => {
        try {
            const response = await axios.get(`/phoenix/messages`);
            setMessages(response.data);
        } catch (error) { console.error(error); }
    }
    fetchMessages();
  }, []);

  return (
      <main id="Messages">
        {messages.map((message) => ( <Message key={message.id} content={message.content} date={message.timestamp} type={message.type} pictures={message.pictures} /> ))}
      </main>
  );
}

export default Messages;