import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

function LettreDetail() {
  const { id } = useParams();
  const [title, setTitle] = useState('');
  const [content, setContent] = useState([]);
  const [date, setDate] = useState('');

  function formatDate(timestamp, options) {
    const date = new Date(timestamp);
    return date.toLocaleDateString('fr-FR', { year: 'numeric', month: 'long', day: 'numeric', hour: "2-digit", minute: "2-digit", timeZone: 'UTC' });
  }

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const response = await axios.get(`/phoenix/lettres/${id}`);
        setTitle(response.data.title);
        setContent(response.data.content);
        console.log(content);
        setDate(formatDate(response.data.timestamp));
      } catch (error) { console.error(error); }
    }
    fetchArticle();
  }, [id]);

  return (
    <div className="page-container">
      <h1 className='Article-title'>{title}</h1> 
      <span className='Article-date'>{date}</span>
      <p className='Article-content'>{content.map((part, index) => (<p key={index}>{part}</p>))}</p>
    </div>
  );
}

export default LettreDetail;