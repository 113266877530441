import React from 'react';
import Picture from './Picture';
import Date from './Date';

const Message = ( {content, type, date, pictures, key} ) => {
    return (
        <div key={key} className='Message'>
            {content} - {type} - {<Date timestamp={date} format="shortDate" />} {<Date timestamp={date} format="hourOnly" />} - {pictures !== null ? pictures.map((picture) => ( <Picture key={picture} link={picture} />)) : null}
        </div>
    );
};

export default Message;