import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';

import Menu from './pages/Menu';
import Lettres from './pages/Lettres';
import OpenWhen from './pages/OpenWhen';
import Messages from './pages/Messages';
import ArticleDetail from './pages/ArticleDetail';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Lettres />} />
          <Route path="/lettres" element={<Lettres />} />
          <Route path="/openwhen" element={<OpenWhen />} />
          <Route path="/messages" element={<Messages />} />
          <Route path="/lettres/:id" element={<ArticleDetail />} />
          <Route path="/openwhen/:id" element={<ArticleDetail />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;