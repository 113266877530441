import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Item from './Item.js';

function ArticleList({ title, typeToFetch }) {
  const [items, setItems] = useState([]);

  useEffect(() => {
    const fetchItems = async () => {
        try {
            const response = await axios.get(`/phoenix/${typeToFetch}`);
            setItems(response.data);
        } catch (error) { console.error(error); }
    }
    fetchItems();
  }, [typeToFetch]);

  return (
    <main>
      <ul className='Item-list'>
        {items.map((item) => ( <Item key={item.id} id={item._id} date={item.timestamp} title={item.title} lock={item.lockedUntil} /> ))}
      </ul>
    </main>
  );
}

export default ArticleList;