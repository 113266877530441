import React from 'react';

const Picture = ({link}) => {
    return (
        <div>
            <img src={link} alt="image supprimé" />
        </div>
    );
};

export default Picture;