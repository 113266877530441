import React from 'react';
import ArticleList from '../components/ArticleList';

function Lettres() {
  return (
    <>
      <ArticleList title="Lettres" typeToFetch="lettres" />
    </>
  );
}

export default Lettres;