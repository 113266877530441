/* eslint-disable no-unused-vars */
import React from 'react';

const DateComponent = ({ timestamp, format }) => {
    function formatDate(timestamp, options) {
        const date = new Date(timestamp);
        return date.toLocaleDateString('fr-FR', options);
    }

    function longDate(timestamp) { return formatDate(timestamp, { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC' }) }
    function shortDate(timestamp) { return formatDate(timestamp, { year: '2-digit', month: '2-digit', day: '2-digit', timeZone: 'UTC'}) }
    
    return (
        <span>
            { format === "hourOnly" ? timestamp.match(/T(\d{2}:\d{2})/)[1] : null }
            { format === "longDate" ? longDate(timestamp) : null }
            { format === "shortDate" ? shortDate(timestamp) : null }
        </span>
    );
};

export default DateComponent;