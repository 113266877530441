import React from 'react';
import { Link } from 'react-router-dom';
import Date from './Date';

const ListItem = ({ key, date, title, id, lock }) => {
    return (
        <li className="Item" id={key}>
            <Link to={`/lettres/${id}`}>
                <div className='Item-name'>{title}</div>
                <div className="Item-date">{<Date timestamp={date} format="longDate" />}</div>
            </Link>
        </li>
    );
};

export default ListItem;