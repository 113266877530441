import React from 'react';
import ArticleList from '../components/ArticleList';

function OpenWhen() {
  return (
    <>
      <ArticleList title="Open When" typeToFetch="openwhen" />
    </>
  );
}

export default OpenWhen;